
import React, { useState, useEffect } from 'react';
import AppHeader from '../AppHeader/AppHeader';
import MetaTags from '../MetaTags';
import AccountSwitcher from '../../Components/AccountSwitcher/AccountSwitcher';
import RoleSwitcher from '../../Components/RoleSwitcher/RoleSwitcher';
import AccountLoadingScreen from '../../Components/AccountLoadingScreen/AccountLoadingScreen';
import ReviewersScreen from '../../Components/ReviewersScreen/ReviewersScreen';
import SideBar from "../../Components/SideBar/SideBar";
import AdminSidebar from "../../Components/Admin/AdminSidebar/AdminSidebar";
import Modal from '../../Components/Modal/Modal';
import Loader from '../../Components/Loader/Loader';
import MsgTune from '../../Components/Audio/sharp.mp3';
import SweetAlert from 'react-bootstrap-sweetalert';
import { MAIN_CONFIG, hideSidebarApplicantList } from '../../../config/main';
import { API_CONFIG } from '../../../config/api';
import '../../Core/Styles/index.scss';
import './app-layout.scss';
import KaleidoscopeAPI from '../../Core/KaleidoscopeAPI';
import FlashMessage from '../../Components/FlashMessage/Message';
import { ToastContainer, toast } from 'react-toastify';
import dynamic from "next/dynamic";
import AdminHeader from '../AppHeader/AdminHeader/AdminHeader';
import { useRouter } from 'next/router';
import { handleRedirectHrefUrl, handleResize, handleSetBeforeLogoutRoute } from '../../Utility/HelperFunctions';
import { DESKTOP_WIDTH, BREAKPOINTS } from '../../Utility/Breakpoints';
import cookies from 'next-cookies';
import {isEmpty} from 'lodash';
import DonorApplicationsSidebar from '../../Components/DonorApplicationsSidebar/DonorApplicationsSidebar';
import { useIdleTimer } from 'react-idle-timer';
import SessionExpireAlert from '../../Components/SessionExpireAlert/SessionExpireAlert';
import { reduxPage } from '../../Core/store';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { IntercomComponent } from '../../Components/Intercom/IntercomComponent';

const ActionCableProviderModule = dynamic(import('react-actioncable-provider').then(module => {
    const { ActionCableProvider } = module
    return ActionCableProvider
}), { ssr: false });

const API = new KaleidoscopeAPI()

const getSelectedScholorships = () => localStorage.getItem("slectedScholoerships")
    ? JSON.parse(localStorage.getItem("slectedScholoerships"))
    : [];
const getSelectedScholorshipsAll = () => localStorage.getItem("slectedScholoershipsAll", "")
    ? localStorage.getItem("slectedScholoershipsAll", "")
    : "";

const getRemoveSelectRowData = () => localStorage.getItem("removeSelectRow")
    ? JSON.parse(localStorage.getItem("removeSelectRow"))
    : [];

const getCurrentSearchVal = () => localStorage.getItem("searchVal") ? localStorage.getItem("searchVal") : ""
const getCurrentSearchKey  = () => localStorage.getItem("searchKey") ? JSON.parse(localStorage.getItem("searchKey")) : ""
const getApplicantFilters = () => localStorage.getItem("applicantFilters") ? JSON.parse(localStorage.getItem("applicantFilters")) : ""
const getActiveTabTable = () =>  localStorage.getItem("activeTabTable") ? localStorage.getItem("activeTabTable") : ""

const AppLayout = ({ children, pageData, accountData, currentPath, userContext, pageType, userAdminContext,previousPath, ...props }) => {
    const isApplicant = (userContext === MAIN_CONFIG.USER_CONTEXT.APPLICANT || userContext === MAIN_CONFIG.USER_CONTEXT.TEAMMEMBER)
    const isAdmin = (userAdminContext === MAIN_CONFIG.USER_CONTEXT.ADMIN)
    const router = useRouter();
    const { action } = router.query;
    const [updateAlert, setUpdateAlert] = useState(false);
    const [showAccountSwitcher, setShowAccountSwitcher] = useState(false);
    const [showAccountLoader, setShowAccountLoader] = useState(false);
    const [accountLoaderAccount, setAccountLoaderAccount] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [showRoleSwitcher, setShowRoleSwitcher] = useState(false);
    const [showRoleLoader, setShowRoleLoader] = useState(false);
    const [accountLoaderRole, setAccountLoaderRole] = useState({});
    const [showTableActionBar, setShowTableActionBar] = useState(false);
    const [downloadTableActionBar, setDownloadTableActionBar] = useState(false);
    const [tableActionBarNumSelected, setTableActionBarNumSelected] = useState(0);
    const [tableActionBarNumTotal, setTableActionBarNumTotal] = useState(0);
    const [activeHeaderNav, setActiveHeaderNav] = useState('');
    const [activeTab, setActiveTab] = useState(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.EXPLORE);
    const [activeAdminTab, setAdminActiveTab] = useState(MAIN_CONFIG.APPLICANT.SIDEBAR_TABS.ADMINLOGIN);
    const [showApplicantOnboarding, setShowApplicantOnboarding] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowsData, setSelectedRowsData] = useState([]);
    const [removeRows, setRemoveRows] = useState([]);
    const [selectedRowsAll, setSelectedRowsAll] = useState("");
    const [currentSearchTermsScholarship, setCurrentSearchTermsScholarship] = useState("");
    const [currentSearchKeyScholarship, setCurrentSearchKeyScholarship] = useState("");
    const [activeAllFilters, setActiveAllFilters] = useState({});
    const [activeTabTable, setActiveTabTable] = useState('');
    const [reviewersCount, setReviewerCount] = useState(pageData.scholarship && pageData.scholarship.total_reviewers)
    const [toggleHamburgerMenu, setToggleHamburgerMenu] = useState(false);
    const [showApplicationsSidebar, setShowApplicationsSidebar] = useState(false)
    const { notifications, heroku_role, marketPlaceData, userInfo } = pageData;
    const [isMobile, setIsMobile] = useState(false);
    const [isIpad, setIsIpad] = useState(false);
    const [showActionBarSpinner, setShowActionBarSpinner] = useState();
    const [isApplicationsClicked, setIsApplicationClicked] = useState(true)
    const [isOpenSweetAlert, setIsOpenSweetAlert] = useState(false)
    const uploadPercentage = useSelector((state) => state.uploadPercentage)
    const [activeDonorSidebarTab, setActiveDonorSidebarTab] = useState("")
    const resize = () => handleResize(setIsMobile, window.innerWidth < DESKTOP_WIDTH);
    const [showTimeModal, setShowTimeModal] = useState(false)
    const isAuthToken = cookies(userContext).token;
    const isAdminAuthToken = cookies(userContext).admintoken;
    const isImpersonatorUser = !!(isAuthToken && isAdminAuthToken)
    const showIntercom = !isImpersonatorUser && process?.env?.NEXT_PUBLIC_ENABLE_INTERCOM === "true"     

    const getRedirectUrl = (row) => {
        if (previousPath) {
            return previousPath
        }
        else if (localStorage.getItem('elementsRBM')) {
            return MAIN_CONFIG.ELEMENTS_URLS.REVIEWER.DASHBOARD
        }
        return row.link
    } 

    useEffect(() => {
        setIsMobile(window.innerWidth < DESKTOP_WIDTH);
        setIsIpad(window.innerWidth > BREAKPOINTS.small && window.innerWidth < BREAKPOINTS.large);
        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
        }
    }, []);

    if (typeof sessionStorage !== 'undefined') {
        // Add a beforeunload event listener to clear the sessionStorage item
        window.addEventListener('beforeunload', function () {
        sessionStorage.removeItem('HideDotManu');
        });
    }
    useEffect(() => {
        document.cookie = `country=;path=/`;
        function getLocation(){
            if (navigator.geolocation){
                navigator.geolocation.getCurrentPosition(showPosition,showError);
            }
            else{
                alert("Geolocation is not supported by this browser.")
            }
        }

        function showPosition(position){
            const lat=position.coords.latitude;
            const lon=position.coords.longitude;
            displayLocation(lat,lon);
        }
        
        function showError(error){
            switch(error.code){
                case error.PERMISSION_DENIED: {
                    const getAlert = () => (
                        <SweetAlert
                            warning
                            confirmBtnCssClass="cta-button CTAWhite tertiary"
                            onConfirm={() => redirectToDashboard()}
                            id="copied-clipboard"
                            confirmBtnText="Okay"
                            title="Oops!"
                        >
                            <p>Please allow Kaleidoscope to access your location.</p>
                        </SweetAlert>
                    );
                    // if(MAIN_CONFIG.PAGE_TYPES.APPLICANT.APPLY === pageType) {
                    //     setUpdateAlert(getAlert)
                    // }
                }
                    // alert("User denied the request for Geolocation.")
                break;
                case error.POSITION_UNAVAILABLE:
                    alert("Location information is unavailable.")
                break;
                case error.TIMEOUT:
                    alert("The request to get user location timed out.")
                break;
                case error.UNKNOWN_ERROR:
                    alert("An unknown error occurred.")
                break;
            }
        }
        
        function displayLocation(latitude,longitude){
            var geocoder;
            geocoder = new google.maps.Geocoder();
            var latlng = new google.maps.LatLng(latitude, longitude);
        
            geocoder.geocode(
                {'latLng': latlng}, 
                function(results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        if (results[0]) {
                            var add= results[0].formatted_address ;
                            var  value=add.split(",");
        
                            let count=value.length;
                            let country=value[count-1];
                            document.cookie = `country=${country};path=/`;
                        }
                        else  {
                            alert("address not found");
                        }
                    }
                    else {
                        alert("Geocoder failed due to: " + status);
                    }
                }
            );
        }

        // if([MAIN_CONFIG.PAGE_TYPES.APPLICANT.APPLY].indexOf(pageType) > -1) {
        //     getLocation()
        // }
    }, [])

    // Update Recommendation question count and completion time
    useEffect(() => {
        if (localStorage.activeTab && localStorage.activeTab !== "Switch Role") {
            setActiveTab(localStorage.activeTab)
        }
    }, [activeTab]);
    useEffect(() => {
        if ((pageData.statusCode === 503 && pageType === MAIN_CONFIG.URLS.LOGIN) || ((userContext === MAIN_CONFIG.USER_CONTEXT.LOGGED_OUT && window.location.pathname != '/callback') && (cookies('/').token || cookies('/').admintoken))) {
            let url = window.location.pathname;

            if (!url.includes('reviewer')) {
                // document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
                // document.cookie = 'admintoken=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
                // document.cookie = 'context=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
                // document.cookie = 'admincontext=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
                // document.cookie = 'user=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
                // document.cookie = 'adminuser=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
                // document.cookie = 'currentAccount=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
                // localStorage.clear();
                sessionStorage.clear()

                setSubmitted(true)
                setTimeout(() => {
                    window.location.href = MAIN_CONFIG.URLS.BASE; // updated from Login
                }, 2000);
            }
        } 
        else {
            var checkPAge = ['LOGIN', "SIGNUP", "RESET_PASSWORD", "SSO_AUTHENTICATION", "SUPER_ADMIN", "SUPER_ADMIN_LOGIN", "SALESFORCE_CALLBACKL", "SPONSORSIGNUP", "PARENTALCONSENT"]
            var checkCommomPage = ['APPLICATIONS','HOME', 'CALLBACK', 'ADMIN', 'APPLY']
            var checkPageForLogoutContext =['APPLICATIONS']
            var pathCollection = ['/client','/reviewer','/recommender','/applicant','/client/admin']
            let splittedPath = document.location.pathname?.split("/").filter(part=>part !== "")
            let path = document.location.pathname
            if (userAdminContext !== 'ADMIN' && (accountData && accountData.token && checkPAge.includes(pageType)) || (accountData && !accountData.token && !checkCommomPage.includes(pageType) && !checkPAge.includes(pageType)) || (accountData && !accountData.token && checkPageForLogoutContext.includes(pageType) && (splittedPath.includes("client") || splittedPath.includes("reviewer"))) || (pageType === MAIN_CONFIG.PAGE_TYPES.DONOR.HOME && accountData && !accountData.token && pathCollection.includes(path)) || (pageType === MAIN_CONFIG.PAGE_TYPES.DONOR.ADMIN && accountData && !accountData.token && pathCollection.includes(path)) ) {
                setSubmitted(true)
                let redirect = userContext === MAIN_CONFIG.USER_CONTEXT.LOGGED_OUT ? MAIN_CONFIG.URLS.LOGIN : MAIN_CONFIG.URLS.BASE 
               router.push(redirect , undefined, { shallow: true })
                // window.location.href = "/"
            } else if (userAdminContext === 'ADMIN' && checkPAge.includes(pageType)) {
                setSubmitted(true)
                router.push('/', undefined, { shallow: true })
            } else {
                let splitArg = document.location.pathname.split("/")
                if (pageType !== "APPLY" && (splitArg.includes("apply") || splitArg.includes("scholarship"))) {
                    setSubmitted(true)
                    handleRedirectHrefUrl(splitArg ? `/scholarships/${splitArg[splitArg.length - 1]}` : "");
                    return false
                    // router.push(`/scholarships/${splitArg[splitArg.length-1]}`, undefined, { shallow: true })
                }
            }

            if(accountData && accountData.token && ((['admin','/', '/applicant'].indexOf(window.location.pathname) > -1) || (window.location.pathname === `/${router.query.indexId}`))){           
                MAIN_CONFIG.USER_ROLE_CONTEXT.map(row=> {
                    if(row.context === (userContext ? userContext : userAdminContext)){
                        if(["UNIVERSITYSTAFF", "HSSTAFF", "COLLEGESTAFF", "DETROITSF"].includes(userContext)){
                            setSubmitted(false)
                        }else{ 
                            if(userContext === "APPLICANT" && accountData?.availableAccounts.length === 0 && accountData?.user?.enable_sso){
                                setSubmitted(true)
                                var marketPlace = ((pageData?.marketPlaceData?.account?.url_name__c || pageData?.marketPlaceData?.account?.sfid) || localStorage.marketPlace);
                                router.push(`${row.link}/${marketPlace}` || row.link, undefined, { shallow: true })
                                setSubmitted(false)
                            }else if(userContext === "REVIEWER"){
                                setSubmitted(true)
                                window.location.href = getRedirectUrl(row)

                            }else{
                                setSubmitted(true)
                                router.push(router.query.indexId ? `${row.link}/${router.query.indexId}` : row.link, undefined, { shallow: true })
                                setSubmitted(false)
                            }
                        } 
                    }
                })
            }
        }
    }, []);
    
    const [reviewersScreenState, setReviewersScreenState] = useState({
        visible: false,
        data: {}
    });
    const [modalState, setModalState] = useState({
        visible: false,
        className: '',
        content: []
    });
    const secondaryModalState = useSelector((state)=> state.secondaryModalReducer)
    const redirectToDashboard = () => {
        // window.location.href = '/';
        setUpdateAlert(false)
    }


    const { getRemainingTime } = useIdleTimer({
        timeout: 1500000, // 25 minutes in milliseconds
        throttle: 500,
    })

    useEffect(() => {
        if (isAuthToken || isAdminAuthToken) {
            const interval = setInterval(() => {
                if (Math.ceil(getRemainingTime() / 1000) < 1) {
                    setShowTimeModal(true)
                }
            }, 500)

            return () => {
                clearInterval(interval)
            }
        }
    }, [])

    const staySignIn = () => {
        setShowTimeModal(false)
    }



    const handleLogout = () => {
        setShowTimeModal(false)
        document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        document.cookie = 'admintoken=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        document.cookie = 'context=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        document.cookie = 'admincontext=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        document.cookie = 'user=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        document.cookie = 'adminuser=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        document.cookie = 'currentAccount=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        localStorage.clear();
        setTimeout(() => {
            window.location.href = MAIN_CONFIG.URLS.LOGIN;
        }, 1000);
    }



    /**
     * Sets the visibility of the Role Switcher.
     * @param {boolean} state
     */
    const setRoleSwitcherVisible = (state) => {
        setShowRoleSwitcher(state);
    }
    /**
     * Sets the visibility of the Role Loader.
     * @param {boolean} state
     * @param {object} account
     */
    const setRoleLoaderVisible = (state, account = {}) => {
        // TODO: Update this with proper logic and account handling
        setAccountLoaderRole(account);
        setShowRoleLoader(state);
    }

    /**
     * Sets the visibility of the Account Switcher.
     * @param {boolean} state
     */
    const setAccountSwitcherVisible = (state) => {
        setShowAccountSwitcher(state);
    }

    const handleReviewScreenClose = () => {
        setReviewersScreenState({ ...reviewersScreenState, visible: false })
    }
    /**
     * Sets the visibility of the Account Loader.
     * @param {boolean} state
     * @param {object} account
     */
    const setAccountLoaderVisible = (state, account = {}) => {
        // TODO: Update this with proper logic and account handling
        setAccountLoaderAccount(account);
        setShowAccountLoader(state);
    }

    // In order to pass props to ALL children, we need to convert to an array so we can map them during render.
    const childrenToRender = React.Children.toArray(children);

    const tableActionBarFunctionOpenExportTable = async (type) => {
        // var clientId = 'iep9k150uloxafy'
        // var localURL = 'http://localhost:3000'
        var localURL = MAIN_CONFIG.URLS.SERVER_URL
        localStorage.setItem("URLRedireact", location.pathname)
        localStorage.setItem("searchVal", currentSearchTermsScholarship)
        localStorage.setItem("searchKey", JSON.stringify(currentSearchKeyScholarship))
        localStorage.setItem("applicantFilters", JSON.stringify(activeAllFilters))
        localStorage.setItem("activeTabTable", activeTabTable)

        const alterPathNameURLs = ["/client/scholarships", "/client/applications", "/reviewer/scholarships"]
        if(alterPathNameURLs.includes(location.pathname)){
            localURL = localURL + location.pathname
            localStorage.setItem('localURL', localURL)
        } else {
            localStorage.setItem("removeID", router.query.scholarshipId)
            localStorage.setItem("scholarshipId", router.query.scholarshipId)
            localStorage.setItem('localURL', localURL + location.pathname)
        }

        localStorage.setItem("urlId", "urlCheck")
        localStorage.setItem('exportType', pageType)

        localStorage.setItem("sectedExportType", JSON.stringify(type))

        if (selectedRowsAll !== "all" && selectedRows.length === 1 && location.pathname !== "/client/scholarships" && location.pathname !== "/client/donations" && location.pathname !== "/client/disbursements") {
            let result = await API.singleCSVPDFExport({ token: accountData.token, accountId: accountData.currentAccount.sfid, application_id: selectedRows[0], type: type, userContext: userContext })
            if (result.document_url) {
              window.open(result.document_url);
            } else if (location.pathname === "/client/applications") {
              if (result.success) {
                toast.success(<FlashMessage message={result.message} />);
              } else {
                toast.error(<FlashMessage message={result.message} />);
              }
            }
            window.location.reload();
        } 
        else if (selectedRowsAll !== "all" && selectedRows.length === 1  && location.pathname ==="/client/donations")
        {
            let result = await API.donationsSingleCSVExport({ token: accountData.token, accountId: accountData.currentAccount.sfid, application_id: selectedRows[0], type: type, userContext: userContext })
            if (result.document_url) {
                window.open(result.document_url)
            }
            window.location.reload();
        }
        else if (selectedRowsAll !== "all" && selectedRows.length === 1  && location.pathname === "/client/disbursements")
        {
            let result = await API.disbursementsSingleCSVExport({ token: accountData.token, accountId: accountData.currentAccount.sfid, application_id: selectedRows[0], type: type, userContext: userContext })
            if (result.document_url) {
                window.open(result.document_url)
            }
            window.location.reload();
        }
        else {
            exportPDForCSV();
        }
    }

    const exportPDForCSV = async () => {
        let result ;
        var type = JSON.parse(localStorage.getItem("sectedExportType"));
        let selectedScholorships = getSelectedScholorships()
        let selectedScholorshipsAll = getSelectedScholorshipsAll()
        let getRemoveSelectRow = getRemoveSelectRowData()
        let currentSearchVal = getCurrentSearchVal()
        let currentSearchKey = getCurrentSearchKey()
        let applicantFilters = getApplicantFilters()
        let activeTabTable = getActiveTabTable()
        let userContext = cookies('context').context
        let userAdminContext = cookies('context').admincontext
        if (userContext === MAIN_CONFIG.USER_CONTEXT.DONOR || userContext === MAIN_CONFIG.USER_CONTEXT.SUPERDONOR) {
            let data = {
                token: accountData.token,
                type,
                is_all_selected: String(selectedScholorshipsAll === "all"),
                search_keys: currentSearchKey,
                search_value: currentSearchVal
            }
            const exportDonorScholorships = async()=>{
                data.selected_scholarship_ids = selectedScholorshipsAll === "all" ? "" : selectedScholorships
                data.removed_scholarship_ids = selectedScholorshipsAll === "all" ? getRemoveSelectRow : ""
                data.accountId = accountData.currentAccount.sfid
                data.scholarship_status = activeTabTable
                data.userContext = userContext

                let output = await API.addScholarshipExport(data)
                return output;
            }

            const exportDonorApplications = async() =>{
                data.selected_application_ids = selectedScholorshipsAll === "all" ? "" : selectedScholorships
                data.removed_application_ids = selectedScholorshipsAll === "all" ? getRemoveSelectRow : ""
                data.accountId = accountData.currentAccount.sfid
                data.application_status = activeTabTable
                data.userContext = userContext
                data.scholarshipId = localStorage.getItem("scholarshipId") ? localStorage.getItem("scholarshipId") : ""

                let output = await API.scholarshipApplicationExport(data)
                return output
            } 

            const exportDonorDonations = async() =>{
                data.selected_application_ids = selectedScholorshipsAll === "all" ? "" : selectedScholorships
                data.removed_application_ids = selectedScholorshipsAll === "all" ? getRemoveSelectRow : ""
                data.accountId = accountData.currentAccount.sfid
                data.applicant_filters = applicantFilters
                data.scholarshipId = localStorage.getItem("scholarshipId") ? localStorage.getItem("scholarshipId") : ""
                data.selected_scholarship_ids = applicantFilters?.scholarship_id||""

                let output = await API.donationsApplicationExport(data)
                return output
            } 

            const exportDonorDisbursements = async() =>{
                data.selected_disbursement_ids = selectedScholorshipsAll === "all" ? "" : selectedScholorships
                data.removed_disbursement_ids = selectedScholorshipsAll === "all" ? getRemoveSelectRow : ""
                data.accountId = accountData.currentAccount.sfid
                data.applicant_filters = applicantFilters
                data.scholarshipId = localStorage.getItem("scholarshipId") ? localStorage.getItem("scholarshipId") : ""
                data.disbursement_status = activeTabTable
                data.selected_scholarship_ids = applicantFilters?.scholarship_id||""
                
                let output = await API.disbursementsApplicationExport(data)
                return output
            } 

            const exportPdfs = async()=>{
                data.selected_application_ids = selectedScholorshipsAll === "all" ? "" : selectedScholorships
                data.removed_application_ids = selectedScholorshipsAll === "all" ? getRemoveSelectRow : ""
                data.accountId = accountData.currentAccount.sfid
                data.applicant_filters = applicantFilters
                
                let output = await API.addApplicantsExport(data)
                return output
            }

            switch(true){
                case (localStorage.exportType === MAIN_CONFIG.PAGE_TYPES.DONOR.SCHOLARSHIPS): 
                    result = await exportDonorScholorships();
                    break;
                case (localStorage.exportType === MAIN_CONFIG.PAGE_TYPES.DONOR.APPLICATIONS):
                    result = await exportDonorApplications();
                    break;
                case (localStorage.exportType === MAIN_CONFIG.PAGE_TYPES.DONOR.DONATIONS):
                    result = await exportDonorDonations();
                    break;
                case (localStorage.exportType === MAIN_CONFIG.PAGE_TYPES.DONOR.DISBURSEMENTS):
                    result = await exportDonorDisbursements();
                    break;
                default: 
                    result = await exportPdfs();
            }
            

        }
    
        if (userContext === MAIN_CONFIG.USER_CONTEXT.REVIEWER) {
            let data = {
                token: accountData.token,
                type,
                is_all_selected: String(selectedScholorshipsAll === "all"),
                search_keys: currentSearchKey,
                search_value: currentSearchVal
            }

            const exportScholorships = async () => {
                data.selected_scholarship_ids = selectedScholorshipsAll === "all" ? "" : selectedScholorships,
                data.removed_scholarship_ids = selectedScholorshipsAll === "all" ? getRemoveSelectRow : ""
                data.accountId = accountData.currentAccount.sfid
                data.userContext = userContext
        
                let output = await API.addScholarshipExport(data)
                return output
            }
            if (localStorage.exportType !== MAIN_CONFIG.PAGE_TYPES.DONOR.HOME) {
                result = exportDonorApplications()
            } else {
                result = exportScholorships()
            }
        }
        if (userAdminContext === MAIN_CONFIG.USER_CONTEXT.ADMIN && isEmpty(userContext)) {
            let admintoken = cookies('context').admintoken
    
            let data = {
                token: admintoken
            }
            result = await API.exportLoginHistory(data)
        }
    
        if (result.success) {
            toast.success(<FlashMessage message={result.message} />);
            // router.push(localStorage.localURL, undefined, { shallow: true })
            if (userContext === MAIN_CONFIG.USER_CONTEXT.DONOR || userContext === MAIN_CONFIG.USER_CONTEXT.SUPERDONOR) {
                window.location.reload()
            }
            // window.location.href = localStorage.localURL
        } else {
            toast.error(<FlashMessage message={result.message} type="error" />);
            // router.push(localStorage.localURL, undefined, { shallow: true })
            window.location.reload()
            // window.location.href = localStorage.localURL
        }
        localStorage.clear();
    }

    const setActiveTabValue = (value) => {
        if (pageType === MAIN_CONFIG.PAGE_TYPES.APPLICANT.APPLY || pageType === MAIN_CONFIG.PAGE_TYPES.APPLICANT.RESUME || pageType === MAIN_CONFIG.PAGE_TYPES.DONOR.APPLICATIONS) {
            setSubmitted(true)
            localStorage.activeTab = value
            // router.push('/applicant', undefined, { shallow: true })

        } else {
            localStorage.activeTab = ""
        }
        setActiveTab(value)
        setModalState({
            visible: false,
            className: '',
            content: []
        })
        if (value === "Switch Role") {
            setRoleSwitcherVisible(true);
        } else {
            setRoleSwitcherVisible(false);
        }

        if (isMobile) {
            setToggleHamburgerMenu(false)
        }
    }
      
    const handleStaySignIn = async () => {
        const result = await API.fetchProfile({
            token: isAdminAuthToken ? isAdminAuthToken : isAuthToken,
        });
        if (result.error == "Not Authorized") {
            handleSetBeforeLogoutRoute()
            handleLogout();
          } else {
            staySignIn();
        }
      };
      
      let activityTimeout = null;
      const inactivityInterval = 60000; // 1 minutes in milliseconds
      let initialAPICalled = false;
      
      const handleActivity = () => {
        if (!initialAPICalled && !showTimeModal) {
          // Call the API initially and set the flag to true
          handleStaySignIn();
          initialAPICalled = true;
        }
      
        clearTimeout(activityTimeout);
        activityTimeout = setTimeout(() => {
          // After inactivity interval, allow API call again on further activity
          initialAPICalled = false;
        }, inactivityInterval);
      };
      
      useEffect(() => {
        // Call the API initially when the component mounts
        if (!showTimeModal && (isAuthToken || isAdminAuthToken)) {
          handleStaySignIn();
      
          // Set up event listeners for user activity (mousemove, keypress, click, scroll)
          document.addEventListener('mousemove', handleActivity);
          document.addEventListener('keypress', handleActivity);
          document.addEventListener('click', handleActivity);
          document.addEventListener('scroll', handleActivity);
      
          return () => {
            document.removeEventListener('mousemove', handleActivity);
            document.removeEventListener('keypress', handleActivity);
            document.removeEventListener('click', handleActivity);
            document.removeEventListener('scroll', handleActivity);
            clearTimeout(activityTimeout);
          };
        }
      }, [showTimeModal]); // Add `showTimeModal` as a dependency to re-run the effect when it changes
      let checkPageType = ['APPLY', 'RESUME']
      let checkUserType = ['APPLICANT', 'LOGGED_OUT']

        return (
        <ActionCableProviderModule url={API_CONFIG.ACTION_CABLE_URL}>
            {showTimeModal && (isAuthToken || isAdminAuthToken) && <SessionExpireAlert handleLogout={handleLogout} staySignIn={staySignIn} />}
            {pageData && <MetaTags pageData={pageData} userContext={userContext} />}
            {submitted ?
                <Loader loading={submitted} />
                :

                <>
                {updateAlert}
                <div id='body-inner'>
                    {
                        ((isMobile && toggleHamburgerMenu)) ? (
                            <SideBar
                                activeTab={activeTab}
                                setActiveTab={setActiveTabValue}
                                marketPlaceData={marketPlaceData}
                                accountData={accountData}
                                setAccountSwitcherVisible={setAccountSwitcherVisible}
                                setAccountLoaderVisible={setAccountLoaderVisible}
                                currentUser={pageData.userInfo}

                                userContext={userContext}
                                roleData={heroku_role}
                                setRoleSwitcherVisible={setRoleSwitcherVisible}

                                setToggleHamburgerMenu={setToggleHamburgerMenu}
                                toggleHamburgerMenu={toggleHamburgerMenu}
                                isMobile={isMobile}
                                pageType={pageType}
                            />
                        ) : null

                    }
                    {
                        isAdmin && !userContext ? (
                            <AdminSidebar
                                activeTab={activeAdminTab}
                                setActiveTab={setAdminActiveTab}
                                userContext={userAdminContext}
                                accountData={accountData}

                                setToggleHamburgerMenu={setToggleHamburgerMenu}
                                toggleHamburgerMenu={toggleHamburgerMenu}
                            />
                        ) : null

                    }

                    <div className={isApplicant && !hideSidebarApplicantList.includes(pageType) ? `${toggleHamburgerMenu && 'applicant-right-side__content-area side-drawer-menu-small-space '}` : (checkPageType.includes(pageType) && (!isMobile) && checkUserType.includes(userContext)) ? 'applicant-application-form-container' : ''} id = 'layout-wrapper'>
                        {
                            modalState.visible &&
                            <>
                                <div className='modal__background'></div>
                                <Modal
                                    id={modalState.id}
                                    className={modalState.className}
                                    isIpad={isIpad}
                                    isMobile={isMobile}
                                >
                                    {modalState.content}
                                </Modal>
                            </>
                        }
                        {
                            secondaryModalState.visible &&
                            <>
                                <div className='modal__background'></div>
                                <Modal
                                    id={secondaryModalState.id}
                                    className={secondaryModalState.className}
                                    isIpad={isIpad}
                                    isMobile={isMobile}
                                >
                                    {secondaryModalState.content}
                                </Modal>
                            </>
                        }
                        {reviewersScreenState.visible &&
                            <ReviewersScreen
                                handleClose={() => handleReviewScreenClose()}
                                reviewersScreenState={reviewersScreenState}
                                setModalState={setModalState}
                                modalState={modalState}
                                accountData={accountData}
                                setReviewerCount={setReviewerCount}
                                loading={submitted}
                                setLoading={setSubmitted}
                                setShowApplicationsSidebar={setShowApplicationsSidebar}
                                handleReviewScreenClose={setReviewersScreenState}
                                isApplicationsClicked = {isApplicationsClicked} 
                                setIsApplicationClicked = {setIsApplicationClicked}
                                setActiveDonorSidebarTab={setActiveDonorSidebarTab}
                                activeDonorSidebarTab={activeDonorSidebarTab}
                            />
                        }
                        {showAccountSwitcher &&
                            <AccountSwitcher
                                accountData={accountData}
                                setAccountSwitcherVisible={setAccountSwitcherVisible}
                                setAccountLoaderVisible={setAccountLoaderVisible}
                            />
                        }
                        {showRoleSwitcher &&
                            <RoleSwitcher
                                userContext={userContext}
                                roleData={heroku_role}
                                setAccountSwitcherVisible={setRoleSwitcherVisible}
                                setAccountLoaderVisible={setRoleLoaderVisible}
                                setActiveTab={setActiveTabValue}
                            />
                        }
                        {showAccountLoader && <AccountLoadingScreen account={accountLoaderAccount} />}

                        {/* { showApplicantOnboarding && <OnboardingScreen setShowApplicantOnboarding={setShowApplicantOnboarding} /> } */}

                        {userContext && 
                                pageType !== MAIN_CONFIG.PAGE_TYPES.DONOR.NEW_SCHOLARSHIP && 
                                pageType !== MAIN_CONFIG.PAGE_TYPES.SSO_AUTHENTICATION && 
                                pageType !== MAIN_CONFIG.PAGE_TYPES.DONOR.EDIT_SCHOLARSHIP &&
                                pageType !== MAIN_CONFIG.PAGE_TYPES.CALLBACK &&
                                pageType !== MAIN_CONFIG.PAGE_TYPES.REVIEWER.IFRAME_APPLICATION_DETAILS &&
                            // Since the builder pages have different actions this needs
                            // to be omitted to allow the header to be rendered on the
                            // page component. This should be integrated into the main
                            // header using redux to handle the component interaction
                            // in the future.
                            <AppHeader
                                data={accountData}
                                currentPath={currentPath}
                                marketPlaceData={marketPlaceData}
                                pageType={pageType}
                                pageData={pageData}
                                setAccountSwitcherVisible={setAccountSwitcherVisible}
                                setAccountLoaderVisible={setAccountLoaderVisible}
                                setModalState={setModalState}

                                roleData={heroku_role}
                                setRoleSwitcherVisible={setRoleSwitcherVisible}
                                setRoleLoaderVisible={setRoleLoaderVisible}

                                notifications={notifications}
                                activeHeaderNav={activeHeaderNav}
                                userContext={userContext}
                                setShowApplicantOnboarding={setShowApplicantOnboarding}

                                isMobile={isMobile}
                                setToggleHamburgerMenu={setToggleHamburgerMenu}
                                toggleHamburgerMenu={toggleHamburgerMenu}
                                accountData = {accountData}
                                isOpenSweetAlert = {isOpenSweetAlert}
                                setIsOpenSweetAlert = {setIsOpenSweetAlert}

                                setLoading = {setSubmitted}
                            />
                        }

                            {
                                showApplicationsSidebar &&
                                <DonorApplicationsSidebar
                                    handleReviewScreenClose={setReviewersScreenState}
                                    setLoading={setSubmitted}
                                    reviewersScreenState={reviewersScreenState}
                                    isApplicationsClicked = {isApplicationsClicked} 
                                    setIsApplicationClicked = {setIsApplicationClicked}
                                    scholarship = {pageType === "EDIT_SCHOLARSHIP" ? pageData?.editScholarshipData.scholarship : pageData?.scholarship}
                                    setModalState = {setModalState}
                                    pageData={pageData}
                                    activeDonorSidebarTab={activeDonorSidebarTab}
                                    setActiveDonorSidebarTab={setActiveDonorSidebarTab}
    
                                />
                            }

                        <main 
                            aria-label="main container" 
                            className={pageType === MAIN_CONFIG.PAGE_TYPES.REVIEWER.IFRAME_APPLICATION_DETAILS ? "applicant-homepage" : isAdmin && !userContext ? toggleHamburgerMenu ? 'admin-main-contain admin-main-contain-expand' : "admin-main-contain" : isApplicant ? pageType === "RESET_PASSWORD" ? "" : isMobile ? 'is-mobile-applicant-logged-in-main-content' : 'applicant-homepage' : ''} 
                            id = {( (checkPageType.includes(pageType) && (!isMobile) && checkUserType.includes(userContext)) ? 'applicant-application-form-container' : pageType === MAIN_CONFIG.PAGE_TYPES.DONOR.SCHOLARSHIP_REVIEWERS ? "donor-reviewer-page" : showApplicationsSidebar ? `donor-applications-sidebar` : pageType === MAIN_CONFIG.PAGE_TYPES.REVIEWER.IFRAME_APPLICATION_DETAILS ? "iframe-reviewer": "")} >
                            {isAdmin && !userContext &&
                                <AdminHeader pageType={pageType} user={pageData.userInfo} accountData = {accountData} setLoading = {setSubmitted}/>
                            }

                            {
                                childrenToRender.map(child => React.cloneElement(child, {
                                setShowTableActionBar,
                                setDownloadTableActionBar,
                                showTableActionBar,
                                downloadTableActionBar,
                                setTableActionBarNumSelected,
                                tableActionBarNumSelected,
                                setTableActionBarNumTotal,
                                tableActionBarNumTotal,
                                setActiveHeaderNav,
                                setReviewersScreenState,
                                setReviewerCount,
                                reviewersScreenState,
                                setModalState,
                                modalState,
                                activeTab,
                                setActiveTab,
                                pageType,
                                selectedRowsData,
                                setSelectedRowsData,
                                setSelectedRows,
                                setRemoveRows,
                                setSelectedRowsAll,
                                setCurrentSearchTermsScholarship,
                                setCurrentSearchKeyScholarship,
                                setActiveAllFilters,
                                activeTabTable,
                                setActiveTabTable,
                                selectedRowsAll,
                                selectedRows,
                                removeRows,
                                currentSearchKeyScholarship,
                                currentSearchTermsScholarship,
                                activeAdminTab,
                                setAdminActiveTab,
                                reviewersCount,
                                setShowActionBarSpinner,
                                setShowApplicationsSidebar,
                                tableActionBarFunctionOpenExportTable,
                                userContext,
                                showActionBarSpinner,
                                setModalState,
                                pageData,
                                activeDonorSidebarTab,
                                setActiveDonorSidebarTab
                            }))}
                        </main>
                    </div>
                    <ToastContainer />

                </div>
                </>
            }
            <audio id='msg-tune'>
                <source src={MsgTune} type='audio/mpeg' />
            </audio>
            {uploadPercentage > 0 && uploadPercentage < 100 && (
          <>
          <div className="upload_container">
        <SweetAlert
        showConfirm={false}
            >
              <div className='upload_container_inner' style={{ display: 'flex' }}>
                <div className='upload_text'>Uploading...</div>
              </div>
                <div className='progress-uploading_section'>
                  <div className='progress-uploading'>
                    <div>
                      <div className='progress-uploading_bar' style={{ width: `${uploadPercentage}%` }}>
                      </div>
                    </div>
                  </div>
                  <div className='progress-uploading_percent BodySmallMediumBlack'>
                    {uploadPercentage}%
                  </div>
                </div>
            </SweetAlert>
            </div>
            </>
            )}
            { showIntercom &&
                <IntercomComponent
                appId="iqc3kx9s"
                userName={pageData?.userInfo?.first_name}
                userEmail={pageData?.userInfo?.email}
                userRoles={pageData?.userInfo?.role}
                isLoggedIn={isAuthToken || isAdminAuthToken}
                />
            }
        </ActionCableProviderModule>
    );
}

// handle proptypes
AppLayout.propTypes = {
    accountData: PropTypes.shape({
        token: PropTypes.string,
        currentAccount: PropTypes.shape({
            sfid: PropTypes.string
        })
    }),
    pageData: PropTypes.shape({
        userInfo: PropTypes.object,
    }),
    setActiveDonorSidebarTab: PropTypes.func,
};


export default reduxPage(AppLayout);