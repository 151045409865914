import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const fetchUserHash = async (email) => {
  try {
    const response = await fetch('/api/generate-hmac', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error);
    }

    const { user_hash } = await response.json();
    return user_hash;
  } catch (error) {
    return null;
  }
};

export const IntercomComponent = ({
  appId,
  userName,
  userEmail,
  userRoles,
  isLoggedIn = false
}) => {
  const [started, setStarted] = useState(false);
  const [userHash, setUserHash] = useState(null);
  const router = useRouter();
  const path = router?.asPath;
  
  // Fetch user hash when email is available and user is logged in
  useEffect(() => {
    const fetchHash = async () => {
      if (userEmail && isLoggedIn) {
        const hash = await fetchUserHash(userEmail);        
        if (hash) setUserHash(hash);
      }
    };
    fetchHash();
  }, [userEmail, isLoggedIn]);

  // Boot Intercom after the user hash is fetched
  useEffect(() => {
    if (!window.Intercom) {
      return;
    }

    const baseData = {
      api_base: "https://api-iam.intercom.io",
      app_id: appId,
    };

    const bootData = isLoggedIn
      ? {
          ...baseData,
          user_id: userEmail,
          name: userName,
          email: userEmail,
          user_hash: userHash,
          created_at: "",
          Roles: userRoles ? userRoles.join(",").toUpperCase() : "",
        }
      : baseData;

    // Boot Intercom Messenger
    window.Intercom("boot", bootData);
    setStarted(true);
  }, [appId, userHash, userName, userEmail, userRoles]);

  useEffect(() => {
    if (!window.Intercom || !started) {
      return;
    }

    window.Intercom("update");
  }, [path, started]);

  return null;
};