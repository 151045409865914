import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import AppPage from '../../../Core/AppPage';
import dynamic from 'next/dynamic'
import './university-staff-home-page.scss';
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
const Globe = dynamic(
    () => import('../../../Components/SVG/Globe'),
    { loading: () => <p>...</p> }
  )

const Refresh = dynamic(
    () => import('../../../Components/SVG/Refresh'),
    { loading: () => <p>...</p> }
  )

const TabbedTablePage = dynamic(
    () => import('../../../Components/TabbedTablePage/TabbedTablePage'),
    { loading: () => <p>...</p> }
  )

const CTAButton = dynamic(
    () => import('../../../Components/CTAButton/CTAButton'),
    { loading: () => <p>...</p> }
  )

import { MAIN_CONFIG } from '../../../../config/main';

const FilterSelector = dynamic(
    () => import('../../../Components/FilterSelector/FilterSelector'),
    { loading: () => <p>...</p> }
  )

const Field = dynamic(
    () => import('../../../Components/Field/Field'),
    { loading: () => <p>...</p> }
  )

const Loader = dynamic(
    () => import('../../../Components/Loader/Loader'),
    { loading: () => <p>...</p> }
  )

import { parseHtml } from '../../../Utility/HelperFunctions';

const StaffUpdateAllForm = dynamic(
    () => import('../../../Components/StaffUpdateAllForm/StaffUpdateAllForm'),
    { loading: () => <p>...</p> }
  )

const FlashMessage = dynamic(
    () => import('../../../Components/FlashMessage/Message'),
    { loading: () => <p>...</p> }
  )

import { useRouter } from 'next/router';

const { USER_CONTEXT } = MAIN_CONFIG;
const { URLS } = MAIN_CONFIG;
export const StaffHome = ({
    pageData,
    accountData,
    setModalState,
    setActiveHeaderNav,
    userContext,
    setSelectedRows,
    setRemoveRows,
    setSelectedRowsAll,
    selectedRows,
    removeRows,
    selectedRowsAll,
}) => {

    const router = useRouter();
    setActiveHeaderNav('dashboard');
    const [tableDataStatusCounts, setTableDataStatusCounts] = React.useState({})
    const { staff_role_instructions__c, applications, application_count_based_on_status, header_properties = [], scholarship_filter_values, pagination } = pageData;
    const API = new KaleidoscopeAPI({});
    // Gather Application Recommendation table data
    // TODO: The recommender will not have this data provided from the back-end, so need to update with proper values
    const allTabData = [

        {
            "subtab_name__c": "Applications to Review ",
            "status_for_api_call__c": "Draft",
            "order__c": 1,
            "table_headers": header_properties || []
        },
        {
            "subtab_name__c": "Applications Submitted ",
            "status_for_api_call__c": "Submitted",
            "order__c": 2,
            "table_headers": header_properties || []
        }
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false); // Tracks current tab
    const rawTableData = applications;
    const rawTableDataProperty = 'applications';
    const pageTitle = 'Applications';
    const searchPlaceholder = 'Search applications';
    const ctaText = `${0} Applications`;
    const ctaIcon = Globe;

    /**
    * Adds the modal content and shows the modal.
    */
    const ctaAction = async(filter) => {
        const res = await API.fetchStaffApplicationSummary({ token: accountData.token, applicationId: JSON.parse(localStorage.slectedScholoerships)[0], userContext })
        if(res){
            setModalState({
              visible: true,
              id: 'staff__update_all',
              content: <StaffUpdateAllForm  application={res.application} applicationStatus={res.staff_role_application_status} handleFormSubmit= {handleFormSubmit} filter={filter} handleClose={handleClose}/>
            });
        }
    }

    /**
    * Closes the modal.
    */
    const handleClose = () => {
        setModalState({ visible: false });
    }

    const handleFormSubmit = async (data, type, studentStatus, filter) => {
        // setLoading(true)

        let applicationsData = {
                            application_data: data,
                            application_status: type,
                            current_status_of_student: studentStatus,
                            is_all_selected: selectedRowsAll === "all" ? "true" : "false",
                            selected_application_ids : selectedRowsAll === "all" ? "" : selectedRows,
                            removed_application_ids : selectedRowsAll === "all" ? removeRows : ""
                          }
        const response = await API.updateALLStaffApplication({ 'token': accountData.token, applicationsData, userContext, options: filter })
        if (response.success) {
            toast.success(<FlashMessage message={response.message} />);
            handleClose()
        } else {
            toast.error(<FlashMessage message={response.message} />);
        }
    }


    const tableDataFetch = async (options) => {
        setShowCheckbox((userContext === USER_CONTEXT.UNIVERSITYSTAFF && options.scholarship) ? true : false)
        const { token } = accountData;
        var result = await API.fetchApplicationsForUniversityStaff({ token, options })
        setTableDataStatusCounts(result.application_count_based_on_status)
        return result
    }
    const tableDataPostFetch = (tableData) => {
        return tableData[rawTableDataProperty] ? tableData[rawTableDataProperty] : [];
    }

    const handleActionClick = (e) => {
        e.preventDefault()
        setIsLoading(true)
        router.push(e.target.href, undefined, { shallow: false})
    }

    /**
     * Gets the CTA to show for a specific applicant.
     * @param {object} applicant
     * @returns {JSX.Element}
     */
    const getRowCta = (application) => {
        // TODO: Add applicant link once account is available for Recommenders
        return (
            <CTAButton type='secondary' small>
                <a href={MAIN_CONFIG.URLS.STAFF_APPLICATION(application.sfid ? application.sfid : application.heroku_id__c)} onClick={(e) => handleActionClick(e)}>View</a>
            </CTAButton>
        );
    }

    const clickTableDataView = (application) => {
        setIsLoading(true)
        router.push(MAIN_CONFIG.URLS.STAFF_APPLICATION(application.sfid ? application.sfid : application.heroku_id__c), undefined, { shallow: false})
        // window.location.href = MAIN_CONFIG.URLS.STAFF_APPLICATION(application.sfid ? application.sfid : application.heroku_id__c)
    }



    let filterAry = []
    header_properties.length > 0 && header_properties.map(r => {
        if (r.enable_search__c) {
            let obj = {
                heading: r.column_name__c,
                type: r.field_api_name__c,
                optionType: 'input',
            }
            filterAry.push(obj)
        }
    })

    const filters = [
        {
            type: "scholarship__c",
            label: "All Programs",
            options: scholarship_filter_values || []
        },


        {
            type: 'nested',
            label: 'Apply Filters',
            optionType: 'modal',
            nestedFilters: filterAry
        }
    ];

    return (
        <>
            <Loader loading={isLoading} />
            <div id='university-staff-applications-page'>
                <div id='university-staff-applications-page__inner'>
                    <TabbedTablePage
                        rawTableData={rawTableData}
                        tableDataFetch={tableDataFetch}
                        tableDataPostFetch={tableDataPostFetch}
                        allTabData={allTabData}
                        pageTitle={pageTitle}
                        pagination={pagination}
                        searchPlaceholder={searchPlaceholder}
                        ctaIcon={ctaIcon}
                        tableDataStatusCounts={tableDataStatusCounts}
                        ctaText={ctaText}
                        ctaAction={ctaAction}

                        clickTableDataView={clickTableDataView}
                        accountData={accountData}

                        userContext={userContext}
                        setSelectedRows={setSelectedRows}
                        setRemoveRows={setRemoveRows}
                        setSelectedRowsAll={setSelectedRowsAll}
                        selectedRows={selectedRows}
                        selectedRowsAll={selectedRowsAll}
                        removeRows={removeRows}

                        id='university-staff-applications-page__inner__content'
                        getRowCta={getRowCta}
                        showSelect={showCheckbox}
                        showRowCta={true}
                        headerBackgroundColor={MAIN_CONFIG.COLORS.fillLightGrey}
                        instructions={parseHtml(staff_role_instructions__c)}
                        filters={filters}
                    />
                </div>
            </div>
        </>
    );

}

const StaffHomePage = AppPage(StaffHome);

export default StaffHomePage;
