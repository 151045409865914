import { useState, useEffect } from 'react'
import ApplicationReviewAttachment from '../ApplicationReviewAttachment/ApplicationReviewAttachment'
import ApplicationReviewText from '../ApplicationReviewText/ApplicationReviewText'
import ApplicationReviewIteration from '../ApplicationReviewIteration/ApplicationReviewIteration'
import ApplicationIterationFinancialInformation from '../ApplicationIterationFinancialInformation/ApplicationIterationFinancialInformation'
import Pencil from '../../../Components/SVG/Pencil';
import RecommenderModalAdd from '../../AddRecommender/RecommenderModal';
import { parseHtml } from '../../../Utility/HelperFunctions';
import FlashMessage from '../../../Components/FlashMessage/Message';
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import Loader from '../../Loader/Loader';
import UserBadge from '../../../Components/UserBadge/UserBadge';
import cookies from 'next-cookies';
import { toast } from 'react-toastify';
import PaymentDetails from '../../Application/PaymentDetails/PaymentDetails'
import './application-review-section.scss'
import AccessCodeModal from '../../Application/AccessCodeModal/AccessCodeModal'
import router from 'next/router'
import { MAIN_CONFIG } from '../../../../config/main'
import { uuid4 } from '@sentry/utils';
import { handleKeyDown } from '../../../Utility/ApplicationFormUtility'

/**
 * Returns Application Review Section component
 *
 * @param {object} props
 * @param {boolean} props.isActive
 * @param {string} props.heading
 * @param {boolean} props.isFirst
 * @param {boolean} props.isLast
 * @param {object[]} props.items
 *
 * @returns {ReactElement}
 */

export default function ApplicationReviewSection(props) {
  const {
    heading,
    description = '',
    editURL = false,
    isFirst,
    isLast,
    items,
    dependentConfigsVariousSteps,
    token,
    setModalState,
    showBadge = false,
    relationship_field_visible__c = false,
    type_of_relationship__c = 'Text',
    relationship_options__c = '',
    herokuRole = '',
    firstName = '',
    lastName = '',
    averageScoreShow = false,
    averageScore = false,
    index,
    checkEnableEdit = false,
    isRecommendationStep = false,
    showDocuUploadButton,
    pre_filled_message__c = '',
    applicationId,
    scholarshipId,
    accountId,
    heroku_id = '',
    stepId = '',
    applicationType,
    scholarshipHideRec,
    hideRecommenderIdentity,
    showAccessCodePopup,
    statusClosed,
    herokuId,
    accessCodePopupDescription = "",
    accessCodeFailureMessage = "",
    accessCodeSuccessMessage = ""
  } = props
  const [
    isActive,
    setIsActive,
  ] = useState(props.isActive)
  const API = new KaleidoscopeAPI({});
  const [loading, setLoading] = useState(false);
  const [updateDetails, setUpdateDetails] = useState({
    name: '',
    email: '',
    firstName: '',
    lastName: '',
    message: '',
    description: ''
  })

  let route = router.query

  useEffect(() => {
    setIsActive(props.isActive)
  }, [props.isActive])

  const userContext = cookies('context').context
  let filteredItems = items.filter(a => (a.content ? (a.content.hide_config_for_roles__c || '') : '').replace(/\s/g, "").split(';').map(a => a.toLowerCase()).indexOf(userContext ? userContext.toLowerCase() : userContext) < 0)
  filteredItems = filteredItems.map((item, index) => {
    item.ordinal = index + 1
    return item
  })


  const filtereditemLength = () => {
    let arr = ['Draft', 'Archived']

    let count = 0

    items.map((item) => {
        if (scholarshipHideRec === false ? true : (!arr.includes(item.recommendationStatus)) ) {
          count = count+1
      }
    })
    return count
  }

  const handleRecommenderSubmit = async (state, questionData, type) => {
    setLoading(true)
    let recommenderData = {
      first_name__c: state.first_name,
      last_name__c: state.last_name,
      email__c: state.email,
      relationship_to_applicant__c: state.relationship,
      reference_message__c: state.message,
      set_number__c: items.length > 0 ? items.filter(a => a.attachmentType === "recommendation").map(a => a.question_set_number).sort().lastItem : 1
    }

    let flag = true

    items.map((item) => {
      if (!state.id) {
        if (item.email === state.email) {
          flag = false
          toast.error(<FlashMessage message={`This email already exists.`} />)
        }
      } else if (item.id != state.id) {
        if (item.email === state.email) {
          flag = false
          toast.error(<FlashMessage message={`This email already exists.`} />)
        }
      }
    })

    if (flag) {
      const response = await API.recommenderAddForClient({ token: token, recommenderData, accountId, scholarshipId, applicationId, applicationStepId: stepId });
      if (response.success) {
        closeRecommenderModal()
        toast.success(<FlashMessage message={response.message} />)
        setUpdateDetails({ firstName: state.first_name, lastName: state.last_name, email: state.email, message: state.message, name: state.first_name + "" + state.last_name, description: state.email })
        window.location.reload()
      } else {
        toast.error(<FlashMessage type="error" message={response.error} />)
      }
    }
    setLoading(false)
  }

  const submitRecommendation = async (questionData, recommendationid, status) => {
    // const { isValid } = validateInput();
    // if (isValid) {
    setLoading(true);
    let clonedData = Object.assign(questionData, []);
    clonedData.filter(record => {
      if (record.document) {
        delete record.document.delete_token
        if (record.document.id)
          delete record.document
      }
      delete record.is_required__c
      delete record.sfid
      if (record.question_answer__c) {
        if (Array.isArray(record.question_answer__c)) {
          let multiAnswer = []
          if (record.answer_type__c === 'Multiselect Picklist') {
            record.question_answer__c && record.question_answer__c.filter(r => multiAnswer.push(r.value))
            record.question_answer__c = multiAnswer.length > 0 ? multiAnswer.join(';') : ''
          } else {
            record.question_answer__c = record.question_answer__c.length > 0 ? record.question_answer__c.join(';') : ''
          }
        } else if (record.question_answer__c.value) {
          record.question_answer__c = record.question_answer__c.value
        } else if (record.question_answer__c.value === "" || record.question_answer__c === "") {
          record.question_answer__c = ""
        } else {
          record.question_answer__c = record.question_answer__c
        }
      }
      delete record.answer_type__c
      return record
    })
    const response = await API.submitApplicantRecommendationQuestions({ token: token, recommendationid, data: clonedData, recommendation_status__c: status })
    if (response.success) {
      toast.success(<FlashMessage message={response.message} />)
    } else {
      toast.error(<FlashMessage type="error" message={response.error}/>)
    }

    hideModal()
    setLoading(false);
    // }
  }

  const closeRecommenderModal = () => {
    setModalState({ visible: false });
  }

  const handleRecommenderAddAction = (e) => {
    e.preventDefault()

    let object = {
      first_name: updateDetails.firstName,
      last_name: updateDetails.lastName,
      email: updateDetails.email,
      message: updateDetails.message,
      id: '',
      message: pre_filled_message__c
    }

    setModalState({
      visible: true,
      id: 'recommender__create',
      content: <RecommenderModalAdd
        handleClose={closeRecommenderModal}
        handleSubmit={handleRecommenderSubmit}
        questions={[]}
        onChange={() => { }}
        staticObj={object}
        applicationDependentConfigs={[]}
        herokuId={''}
        MsgText={''}
        relationshipFieldVisible={relationship_field_visible__c}
        relationshipType={type_of_relationship__c}
        relationshipOptions={relationship_options__c || ''}
        type={'new'}
        recommenderList={items.filter(a => a.attachmentType === "recommendation")}
        dependentConfigsForVariousSteps={[]}
        herokuRole={herokuRole}
        editRecommenderDetails={true}
      />
    });
  }

  
  let checkStepType = filteredItems.find((item)=>{
    return item.type === 'attachment' && item.attachmentType === "recommendation"
  })

  const apiCallEdit = async ()=>{

    const response = await API.checkAccessCode({ 'token': cookies('context').token, scholarshipId: router.query.scholarshipId, code: localStorage.getItem('accessCode'), applicationId: herokuId })

    return response
  }

  const checkActive = () => {
    if (window.self !== window.top) {
      return isActive ? true : false
    }
    return true
  }
  
  const handleEditClick = async ()=>{
    const hasAccessCode = JSON.parse(localStorage.getItem('hasAccessCode'));
    const needsAccessCode = !hasAccessCode?.includes(herokuId);
    
    if (showAccessCodePopup) {
      if (needsAccessCode) {
      setModalState({
        visible: true,
        id: 'access-code-modal-content',
        content:<AccessCodeModal
        token={token}
        setLoading={setLoading}
        redirectURL={editURL}
        applicationId = {herokuId}
        setModalState = {setModalState}
        accessCodePopupDescription={accessCodePopupDescription}
        accessCodeFailureMessage={accessCodeFailureMessage}
        accessCodeSuccessMessage={accessCodeSuccessMessage}
      />
      });
    } else {
        const apiCall = await apiCallEdit();
      if (apiCall.success) {
        setLoading(true)
        router.push(`${editURL}`, undefined, { shallow: true })
      }else{
        toast.error(<FlashMessage message={apiCall.message} />);
        router.push(`/applicant`, undefined, { shallow: true })
        localStorage.removeItem('hasAccessCode')
        localStorage.removeItem('accessCode')
        }
      }
    }else{
      let showBackButton = {
        backButton: true,
        applicationIdForBack: router?.query?.applicationId??''
      };
      sessionStorage.setItem('showBackButton', JSON.stringify(showBackButton));
      router.push(`${editURL}`, undefined, { shallow: true })
    }
  }
  const showEdit=editURL && (applicationType !== "Donation Form" || showAccessCodePopup)

  return (
    <section
      className={`
        application-review-section
        ${isActive ? 'is-active' : ''}
        ${isFirst ? 'application-review-section--first' : ''}
        ${isLast ? 'application-review-section--last' : ''}
      `}
      key={index}
    >
      <Loader
        loading={loading}
      />
      <div className="application-review-section__top" style={{ justifyContent: userContext !== "APPLICANT" && 'space-between', flexWrap: userContext !== "APPLICANT" && 'unset'}}>
        {userContext === "APPLICANT" && 
      <div className='box' id={uuid4()}> 
          <div class="nav-col">
            <div class="icon">
              <div>{index + 1}.</div>
            </div>
          </div>
        </div>}

        <div className="application-review-section__user-badge-with-heading H1DesktopBlack box" id={uuid4()}>
          {
            showBadge && (
              <UserBadge
                firstName={firstName}
                lastName={lastName}
              />
            )
          }
          <div>
            <h1 className="application-review-section__heading application-step-title">
              {parseHtml(heading)}
            </h1>
          </div>

        </div>

      <div className='edit-and-toggle-button'>
      <div className='box end' id={uuid4()}>
      <div className="application-steps-preview-edit-button">
          {
            showEdit &&
                <div className="application-steps-section-edit-button-container" onClick={handleEditClick} id={index + 1} tabIndex={showEdit ? 0 : -1} role='button' onKeyDown={(e)=>handleKeyDown(e,handleEditClick)}>
              <a className="application-steps-section-edit-button-container__edit-button" tabIndex={-1}>
                <Pencil tabIndex={-1} width="15" height="15" color ={ MAIN_CONFIG.COLORS.fillBlack} className="application-steps-section-edit-button-container__edit-button__edit-icon" ariaLabel="Edit" /> Edit
              </a>
            </div>
          }
        </div>
        </div>
        <div className='box end' id={uuid4()}>

          <button
            className="application-review-section__section-toggle"
            type="button"
            onClick={() => setIsActive(!isActive)}
            aria-label={`Scholarship step toggle options ${isActive ? "button active" : "button inactive"}`}
            style={{border: `1px solid rgb(30, 85, 88)`, height: '20px'}}
          >
          {userContext === "REVIEWER" ? (averageScoreShow ? averageScore : checkStepType ? filtereditemLength() : filteredItems.length) : (averageScoreShow ? averageScore :  filteredItems.length)} 
          </button>
        </div>
      </div>
      </div>
      {checkActive() && (
         renderContent(
          filteredItems,
          token,
          setModalState,
          heading,
          editURL,
          dependentConfigsVariousSteps,
          checkEnableEdit,
          showDocuUploadButton,
          applicationId,
          scholarshipId,
          accountId,
          applicationType,
          scholarshipHideRec,
          hideRecommenderIdentity,
          setLoading,
          showAccessCodePopup,
          route,
          statusClosed,
          herokuId,
          apiCallEdit,
          isRecommendationStep,
          handleRecommenderAddAction,
          stepId,
          herokuRole,
          relationship_field_visible__c,
          type_of_relationship__c,
          relationship_options__c      
        )
      )}
    </section>
  )
}

/**checkEnableEdit
 * Returns section content
 *
 * @param {object[]} items
 *
 * @returns {ReactElement}
 */

function renderContent(
          items,
          token,
          setModalState,
          heading,
          editURL,
          dependentConfigsVariousSteps,
          checkEnableEdit,
          showDocuUploadButton,
          applicationId,
          scholarshipId,
          accountId,
          applicationType,
          scholarshipHideRec,
          hideRecommenderIdentity,
          setLoading,
          showAccessCodePopup,
          route,
          statusClosed,
          herokuId,
          apiCallEdit,
          // Default parameters come last
          isRecommendationStep = false,
          handleRecommenderAddAction = () => {},
          stepId = '',
          herokuRole = '',
          relationship_field_visible__c = false,
          type_of_relationship__c = 'Text',
          relationship_options__c = ''
        ) {
  const content = []
  let userContext = cookies('context').context
  let srno = 0
  for (let i = 0, total = items.length; i < total; i++) {
    const item = items[i]
    
    if (item.type === 'attachment' && item.attachmentType !== "recommendation") {
      content.push(
        <ApplicationReviewAttachment
          type={item.attachmentType}
          name={item.name}
          description={item.description}
          relationship={item.relationship}
          sfid={item.sfid}
          token={token}
          setModalState={setModalState}
          key={i}
          applicationUpdateFunction={item.applicationUpdateFunction}
          email={item.email}
          firstName={item.first_name}
          lastName={item.last_name}
          heroku_id={item.heroku_id}
          message={item.message}
          herokuRole={item.herokuRole}
          items={items}
          id={item.id}
          stepId={stepId}
          showDocuUploadButton={showDocuUploadButton}
          applicationId={applicationId}
          scholarshipId={scholarshipId}
          accountId={accountId}
          relationship_field_visible__c={relationship_field_visible__c}
          type_of_relationship__c={type_of_relationship__c}
          relationship_options__c={relationship_options__c}
        />
      )
    } else if (item.type === 'attachment' && item.attachmentType === "recommendation") {
      let arr = ['Draft', 'Archived'] 

      if ( scholarshipHideRec=== false ? true :  (!arr.includes(item.recommendationStatus))) {
        content.push(
          <ApplicationReviewAttachment
            type={item.attachmentType}
            name={item.name}
            description={item.description}
            relationship={item.relationship}
            sfid={item.sfid}
            token={token}
            setModalState={setModalState}
            key={i}
            applicationUpdateFunction={item.applicationUpdateFunction}
            email={item.email}
            firstName={item.first_name}
            lastName={item.last_name}
            heroku_id={item.heroku_id}
            message={item.message}
            herokuRole={item.herokuRole}
            items={items}
            id={item.id}
            stepId={stepId}
            showDocuUploadButton={showDocuUploadButton}
            applicationId={applicationId}
            scholarshipId={scholarshipId}
            accountId={accountId}
            relationship_field_visible__c={relationship_field_visible__c}
            type_of_relationship__c={type_of_relationship__c}
            relationship_options__c={relationship_options__c}
            buttonStatus = {!scholarshipHideRec && item.recommendationStatus === "Draft"}
            hideRecommenderIdentity={hideRecommenderIdentity}
            applicant_recommendation_name={item?.applicant_recommendation_name}
          />
        )
      }
    } else if (item.type === 'iteration') {
      content.push(
        <ApplicationReviewIteration
          items={item.items}
          title={`${heading} Set Number ${item.setNumber}`}
          key={i}
          dependentConfigsVariousSteps={dependentConfigsVariousSteps}
          deleteApplicationDocument={item.deleteApplicationDocument}
          addApplicationDocument={item.addApplicationDocument}
          applicationUpdateFunction={item.applicationUpdateFunction}
          FormType={item.type}
          setModalState={setModalState}
        />
      )
    } else if (item.type === 'FinancialInformation') {
      content.push(
        <ApplicationIterationFinancialInformation
          items={item.items}
          title={`${heading} Set Number ${item.setNumber}`}
          key={i}
          dependentConfigsVariousSteps={dependentConfigsVariousSteps}
          deleteApplicationDocument={item.deleteApplicationDocument}
          addApplicationDocument={item.addApplicationDocument}
          applicationUpdateFunction={item.applicationUpdateFunction}
          setModalState={setModalState}
        />
      )
    } else if (item.type === 'Payment') {
      content.push(
        <PaymentDetails
          title={item.items.title__c}
          subTitle={item.items.description__c}
          paymentPageInfo={item.items.payment_step_info}
          application={item.items}
        />
      )
    } else if (item.type === 'text') {
      if (item.dataType !== 'Output Message') {
        srno = srno + 1
      }
      content.push(
        <ApplicationReviewText
          ordinal={srno}
          heading={item.heading}
          desciptionQuestion={item.content.description__c}
          content={item.content}
          firstName={item.firstName}
          lastName={item.lastName}
          dataType={item.dataType}
          documents={item.documents}
          wordCount={item.wordCount}
          isFirst={i === 0}
          key={item.content.sfid}
          deleteApplicationDocument={item.deleteApplicationDocument}
          addApplicationDocument={item.addApplicationDocument}
          applicationUpdateFunction={item.applicationUpdateFunction}
          setModalState={setModalState}
        />
      )
    }
  }

  const handleEditClick = async ()=>{
    let arr = JSON.parse(localStorage.getItem('hasAccessCode'))
    const apiCall = await apiCallEdit();
    if( statusClosed && showAccessCodePopup && (arr ?  arr.includes(herokuId) === false : true)){
      setModalState({
        visible: true,
        id: 'recommender__create',
        content:<AccessCodeModal
        token={token}
        // scholarshipId={scholarshipId}
        setLoading={setLoading}
        redirectURL={editURL}
        applicationId = {herokuId}
        setModalState = {setModalState}
      />
      });
    }else if(showAccessCodePopup && statusClosed) {
      if (apiCall.success) {
        setLoading(true)
        router.push(`${editURL}`, undefined, { shallow: true })
      }else{
        toast.error(<FlashMessage message={apiCall.message} />);
        router.push(`/applicant`, undefined, { shallow: true })
        localStorage.removeItem('hasAccessCode')
        localStorage.removeItem('accessCode')
      }
    }else{
      router.push(`${editURL}`, undefined, { shallow: true })
    }
  }
  

  return (
    <div className="application-review-section__content">
      <div className="application-steps-preview-content-and-edit-button-container">
        <div className="application-steps-preview-content">
          {userContext === "DONOR" && <div className='handle-recommender-add-action'>
            {
              isRecommendationStep && (
                <button className="application-review-attachment__trigger add-recommender" aria-label={`Add button`} onClick={(e) => handleRecommenderAddAction(e)}>
                  Add {herokuRole === 'School Counsellor' ? 'School Counselor' : herokuRole}
                </button>
              )
            }
          </div>}


          {
            content
          }
        </div>
        {/* <div className="application-steps-preview-edit-button">
          {
            (editURL && applicationType !== "Donation Form" || showAccessCodePopup) &&
            <div className="application-steps-section-edit-button-container" onClick={handleEditClick}>
              <a className="application-steps-section-edit-button-container__edit-button">
                <Pencil width="15" height="15" className="application-steps-section-edit-button-container__edit-button__edit-icon" ariaLabel="Edit" /> Edit
              </a>
            </div>
          }
        </div> */}
      </div>
    </div>
  )
}
