import React, { useMemo } from "react";
import "./DonorDashboardScholarshipCardChart.scss";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import CircleCheck from "../SVG/CircleCheck";

const DonorDashboardScholarshipCardChart = ({
  scholarship,
  handleRedirect = () => {},
}) => {
  const { name, application_count_based_on_status, elements_program__c } = scholarship;

  const colors = {
    Draft: "#CDE9FF",
    Complete: "#003F3F",
    "Pending Contributor Submissions": "#6A1B6A",
    Other: "#FFE3E3",
  };

  const legendData = useMemo(
    () =>
      Object.keys(colors).map((key) => ({
        name: key,
        value: application_count_based_on_status[key] || 0,
        color: colors[key] || "#ff9999",
      })),
    [application_count_based_on_status]
  );

  const filteredData = useMemo(
    () =>
      Object.entries(application_count_based_on_status)
        .filter(([key, value]) => !(elements_program__c && key === "Other") && value > 0)
        .map(([key, value]) => ({
          name: key,
          value,
          color: colors[key] || "#ff9999",
        })),
    [application_count_based_on_status]
  );

  const totalValue = useMemo(
    () => filteredData.reduce((sum, item) => sum + item.value, 0),
    [filteredData]
  );

  const renderCustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
    name,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    let x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
    let y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));
    const percentage = ((value / totalValue) * 100).toFixed(0);

    if (totalValue <= 1) {
      x = "50%";
      y = "50%";
      value = totalValue === 0 ? "No Data" : value;
    } else if (percentage < 5) {
      return null;
    }

    const textColor = ["Pending Contributor Submissions", "Complete"].includes(
      name
    )
      ? "#FFFFFF"
      : totalValue === 0
      ? "#888888"
      : "#000000";
    return (
      <text
        x={x}
        y={y}
        fill={textColor}
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={16}
      >
        {value}
      </text>
    );
  };

  const startAngle = useMemo(() => {
    if (filteredData.length) {
      const completeIndex = filteredData.findIndex(
        (item) => item.name === "Complete"
      );
      if (completeIndex === -1 || filteredData[completeIndex].value === 0) {
        return 90 - (filteredData[0].value / totalValue) * 360;
      }
    }
    return 90; // Default start angle
  }, [filteredData, totalValue]);

  return (
    <button
      className="scholarship-card-pie-chart-main-container"
      onClick={(e) => handleRedirect(e, scholarship)}
    >
      <div className="scholarship-card-pie-chart-main-container__header H6DesktopWhite">
        {name}
      </div>

      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={
              totalValue === 0
                ? [{ name: "No Data", value: 1 }]
                : filteredData
            }
            cx="50%"
            cy="50%"
            outerRadius={100}
            dataKey="value"
            startAngle={startAngle}
            endAngle={startAngle - 360}
            label={renderCustomLabel}
            labelLine={false}
            stroke="none"
          >
            {totalValue === 0 ? (
              <Cell fill="#E0E0E0" />
            ) : (
              filteredData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))
            )}
          </Pie>

          {totalValue !== 0 && (
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const { name, value } = payload[0].payload;
                  const percentage = ((value / totalValue) * 100).toFixed(2);
                  return (
                    <div className="pie-chart-tooltip">
                      <strong>{name}</strong>
                      <br />
                      Count: {value} ({percentage}%)
                    </div>
                  );
                }
                return null;
              }}
              wrapperStyle={{ zIndex: 1000 }}
            />
          )}
        </PieChart>
      </ResponsiveContainer>

      {legendData.map((entry, index) => (
        <div
          key={index}
          className="scholarship-card-pie-chart-main-container__footer"
        >
          {!(elements_program__c && entry.name === "Other") && (
            <>
              <div className="scholarship-card-pie-chart-main-container__footer__color-circel">
                <CircleCheck
                  id={`${entry.name}-${index}`}
                  className="application-radio-buttons__icon"
                  color={entry.color}
                  secondaryColor={entry.color}
                  stroke={entry.color}
                />
              </div>

              <div className="scholarship-card-pie-chart-main-container__footer__label-content">
                {entry.name}
              </div>
            </>
          )}
        </div>
      ))}
    </button>
  );
};
export default DonorDashboardScholarshipCardChart;
