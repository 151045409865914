import React from 'react';
import ApplicationReviewText from '../ApplicationReviewText/ApplicationReviewText'
import UserBadge from '../../UserBadge/UserBadge';
import CTAButton from '../../../Components/CTAButton/CTAButton';
import './RecommendationDetails.scss'
import { skipConfigIds, skipConfigValue } from '../../../Utility/ApplicationFormUtility';
import { MAIN_CONFIG } from '../../../../config/main';
import PropTypes from 'prop-types';

/**
 *
 * @param {object} data
 * @param {function} handleClose
 * @returns {JSX.Element}
 */
const RecommendationDetails = ({
	details = {},
	handleClose = () => {},
	name,
	email,
	setModalState = () => { },
	userContext = ""
}) => {
	var srno = 0
	const {applicant_recommendations} = details;

	const hideRecommenderIdentity = userContext === MAIN_CONFIG.USER_CONTEXT.REVIEWER && applicant_recommendations.hide_recommender_identity__c;

	const filterQuestions = ()=> {

    const recordIds = skipConfigIds(details.recommendation_questions, []);
    const recordValue = skipConfigValue(recordIds, []);
  
    return userContext !== MAIN_CONFIG.USER_CONTEXT.REVIEWER
      ? recordValue
      : recordValue.filter((item) => !item.hide_from_reviewer__c);
  };

	return (
    <div id='review-details-page__recommendation-modal-content'>
			 <div className="review-details-page-popup__user-details">
        {hideRecommenderIdentity ? (
          <span className="H1DesktopGreen">
            {applicant_recommendations?.name}
          </span>
        ) : (
          <>
            <div className="review-details-page-popup__profile-icon">
              <div id="account-menu__profile-icon">
                <UserBadge
                  firstName={applicant_recommendations.recommender_ff__c}
                />
              </div>
            </div>
            <div className="review-details-page-popup__profile-info">
              <div className="review-details-page-popup__profile-name application-review-attachment__heading">
                {name}
              </div>
              <div className="review-details-page-popup__profile-email application-review-attachment__small">
                {email}
              </div>
            </div>
          </>
        )}
      </div>

			{
				filterQuestions()?.map((item, index) => {
					if(item.answer_type__c !== 'Output Message'){
				        srno = srno + 1
				    }
					return(
						<ApplicationReviewText
							ordinal={ srno }
							heading={ item.question_label__c }
							content={ item }
							dataType={ item.answer_type__c }
							desciptionQuestion= {item.question_description__c}
							documents={ item.documents ? item.documents : item.document_name__c ? [{doc_name__c: item.document_name__c, doc_url__c: item.document_url__c}] : [] }
							wordCount={ item.word_count__c}
							isFirst={ index === 0 }
							key={ item.sfid }
							recommendation={true}
							setModalState={setModalState}
						/>
					)
				})
			}
			<div className="review-details-page-popup__footer">
				<CTAButton id='review-details_close-btn' type='tertiary' onClick={handleClose}>
					Close
				</CTAButton>
			</div>
		</div>
	);
};

RecommendationDetails.propTypes = {
  handleClose: PropTypes.func,
  name: PropTypes.string,
  email: PropTypes.string,
  setModalState: PropTypes.func,
  userContext: PropTypes.string,
};

export default RecommendationDetails;
