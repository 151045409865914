import PropTypes from 'prop-types'
import ApplicationReviewSection from '../ApplicationReviewSection/ApplicationReviewSection'

import './application-review-sections.scss'

/**
 * Returns Application Review Sections component
 *
 * @param {object} props
 * @param {object[]} props.sections
 *
 * @returns {ReactElement}
 */

export default function ApplicationReviewSections(props) {
  const {
    sections = [],
    token,
    setModalState,
    showBadge,
    activeReviewer = '',
    applicationUpdateFunction = false,
    checkEnableEdit = false,
    showDocuUploadButton,
    applicationId,
    scholarshipId,
    accountId,
    applicationType ,
    scholarshipHideRec,
    hideRecommenderIdentity,
    showAccessCodePopup,
    statusClosed,
    herokuId,
    accessCodePopupDescription = "",
    accessCodeFailureMessage = "",
    accessCodeSuccessMessage = ""
  } = props
  return (
    <div className="application-review-sections H5DesktopGrey">
      {sections.length > 0 ? 
      renderSections(
        sections,
        token,
        setModalState,
        showBadge,
        checkEnableEdit,
        showDocuUploadButton,
        applicationId,
        scholarshipId,
        accountId,
        applicationType,
        scholarshipHideRec,
        hideRecommenderIdentity,
        showAccessCodePopup,
        statusClosed,
        herokuId,
        accessCodePopupDescription,
        accessCodeFailureMessage,
        accessCodeSuccessMessage
        ) : ''}
    </div>
  )
}

/**
 * Returns Application Review Sections
 *
 * @param {object[]} sections
 * @param {boolean} sections[].isActive
 * @param {string} sections[].heading
 * @param {object[]} sections[].items
 *
 * @returns {ReactElement}
 */

function renderSections(
          sections,
          token,
          setModalState,
          showBadge,
          checkEnableEdit,
          showDocuUploadButton,
          applicationId,
          scholarshipId,
          accountId,
          applicationType,
          scholarshipHideRec,
          hideRecommenderIdentity,
          showAccessCodePopup,
          statusClosed,
          herokuId,
          accessCodePopupDescription,
          accessCodeFailureMessage,
          accessCodeSuccessMessage
        ) {
  const content = []
  for (let i = 0, total = sections.length; i < total; i++) {
    const section = sections[i]
    content.push(
      <ApplicationReviewSection
        token={token}
        showBadge={showBadge}
        setModalState={setModalState}
        isActive={section.isActive }
        heading={section.heading }
        description={section.description}
        editURL={section.editURL || false}
        isRecommendationStep={section.isRecommendationStep}
        isFirst={i === 0}
        isLast={i === sections.length - 1}
        items={section.items}
        firstName={section.firstName}
        pre_filled_message__c={section.pre_filled_message__c}
        lastName={section.lastName}
        averageScore={section.averageScore}
        averageScoreShow={section.averageScoreShow}
        dependentConfigsVariousSteps={section.dependentConfigsVariousSteps || []}
        key={i}
        relationship_field_visible__c={section.relationship_field_visible__c || false}
        type_of_relationship__c={section.type_of_relationship__c || 'Text'}
        relationship_options__c={section.relationship_options__c || ''}
        herokuRole={section.herokuRole}
        index={i}
        checkEnableEdit={checkEnableEdit}
        showDocuUploadButton={showDocuUploadButton}
        applicationId={applicationId}
        scholarshipId={scholarshipId}
        stepId={section.stepId}
        accountId={accountId}
        applicationType = {applicationType}
        scholarshipHideRec = {scholarshipHideRec}
        hideRecommenderIdentity={hideRecommenderIdentity}
        showAccessCodePopup = {showAccessCodePopup}
        statusClosed = {statusClosed}
        herokuId = {herokuId}
        accessCodePopupDescription={accessCodePopupDescription}
        accessCodeFailureMessage={accessCodeFailureMessage}
        accessCodeSuccessMessage={accessCodeSuccessMessage}
      />
    )
  }

  return content
}

ApplicationReviewSections.propTypes = {
  hideRecommenderIdentity: PropTypes.bool
}